import React, { useState, useEffect, forwardRef } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; // Grid version 1
import OrariMesse from '../components/OrariMesse';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import DatePicker, { registerLocale } from "react-datepicker";
import it from 'date-fns/locale/it';


import "react-datepicker/dist/react-datepicker.css";

const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
  <ButtonGroup
      disableElevation
      variant="contained"
      aria-label="Disabled elevation buttons"
    >
      <Button variant="outlined" onClick={onClick} >Cambia data:</Button>
      <Button size="large" variant="contained" onClick={onClick} ref={ref}>
        {value}
      </Button >
  </ButtonGroup>
));


const today = new Date()
const tomorrow_ = new Date(today)
tomorrow_.setDate(today.getDate() + 1)

const OrariMessePage = () => {

  const [startDate, setStartDate] = useState(new Date());
  const [tomorrow, setTomorrowDate] = useState(tomorrow_);

  registerLocale('it', it)

  function updateDate(d) {
    setStartDate(d)

    const dateCopy = new Date(d);
    dateCopy.setDate(d.getDate() + 1);
    setTomorrowDate(dateCopy)
  }

  useEffect(() => {
  }, [startDate, tomorrow])

  return (
    <Container>
      <br></br>
      <center>
        <DatePicker
          selected={startDate}
          onChange={(date) => updateDate(date)}
          locale="it"
          dateFormat="P"
          customInput={<DatePickerCustomInput />}
        />
      </center>
      <br></br>
      <Grid  item xs={12} md={2}>
        <OrariMesse date={startDate}></OrariMesse>
        <br></br>
        <OrariMesse date={tomorrow}></OrariMesse>
      </Grid>
    </Container>
  );
};
  
export default OrariMessePage;