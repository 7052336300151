function day_of_the_month(d) { 
    return (d.getDate() < 10 ? '0' : '') + d.getDate();
  }

export default function loadOrariMesseInfo(date) {
    return fetch(`data/messe.json`)
    .then((r) => r.json())
    .then((data) =>{
        const monthName = date.toLocaleString('en', { month: 'short' }).toLowerCase();
        const dayName = date.toLocaleString('en', { weekday: 'short' }).toLowerCase();
        const day = day_of_the_month(date)
        const d = day + monthName

        let messe = []
        // if day is sunday
        if( data.messe[d+"sun"] ) {
          messe = data.messe[d+"sun"]
        } else if( data.messe[d] ) {
          messe = data.messe[d]
        } else if( data.messe[day+"xx"] ) {
          messe = data.messe[day+"xx"]
        } else if( data.messe[dayName] ) {
          messe = data.messe[dayName]
        } else {
          messe = data.messe["feriali"]
        }
        return { 'orari': messe }
    })
  }