import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import InfoIcon from '@mui/icons-material/Info';
import PhotoIcon from '@mui/icons-material/Photo';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ChurchIcon from '@mui/icons-material/Church';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

const pages = [ 
  { 'label': 'Il Santuario', 'key': 'santuario', 'icon': <ChurchIcon />},
  { 'label': 'Le Suore', 'key': 'suore', 'icon': <SupervisedUserCircleIcon />}, 
  { 'label': 'Le Opere', 'key': 'opere', 'icon': <WorkspacePremiumIcon />},
  { 'label': 'Foto&Video', 'key': 'foto_video', 'icon': <PhotoIcon />},
  { 'label': 'Orari Messe', 'key': 'orari_messe', 'icon': <ScheduleIcon />},
  { 'label': 'Informazioni', 'key': 'info', 'icon': <InfoIcon />},
  { 'label': 'Scrivici', 'key': 'scrivici', 'icon': <InfoIcon />}
]; 
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
  
function Navbar () {

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
    <AppBar>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.key} onClick={() => { window.location.href = "/" + page.key }}>
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img alt="home page" src="images/header.png"/>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Button><a href="/"><img alt="home page" src="images/header.png"/></a></Button>
            {pages.map((page) => (
              <Button
                key={page.key}
                startIcon={page.icon}
                onClick={() => { window.location.href = "/" + page.key }}
                sx={{ my: 2, color: 'white' }}
              >
                {page.label}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
    </AppBar>
    </>
  );
};
  
export default Navbar;