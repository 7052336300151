import React from "react";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import EmailIcon from '@mui/icons-material/Email';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';



// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();



const Scrivici = () => {

  const [sent, setSent] = useState(false);

  const send_data = (data) => {
    fetch('https://sendmail-wza7oyeinq-uc.a.run.app', {
      
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      })
      .then((response) => response.json())
      .then((data) => {
        setSent(true)
      })
      .catch((err) => {
        alert(err)
        console.log(err.message);
      });
  }

  const validate_data = (d) => {
    var nome = d.name
    var email = d.email;
    var messaggio = d.message;

    // Verifica se tutti i campi sono stati compilati
    if (nome === "" || email === "" || messaggio === "") {
        return "Tutti i campi sono obbligatori. Si prega di compilare tutti i campi.";
    }

    // Verifica il formato dell'indirizzo email
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
        return "Inserire un indirizzo email valido.";
    }

    return true;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const d = {
      name: data.get('name'),
      email: data.get('email'),
      message: data.get('message')
    }
    const valid_res = validate_data(d)
    if(valid_res === true) {
     send_data(d)
    } else {
      alert(valid_res)
    }
  };
  if(sent) {
    return (<Container component="main" maxWidth="xs"><h2>Messaggio inviato. <br></br> Al più presto ti risponderemo</h2></Container>)
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <EmailIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Scrivici
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Nome (Name)"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="message"
                  label="Messaggio (Message)"
                  multiline
                  rows={5}
                  id="message"
                  autoComplete="message"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Invia (Send)
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
};

  
export default Scrivici;