import './App.css';
import * as React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import Home from './pages/Home';
import Info from './pages/info';
import FotoVideo from './pages/foto_video';
import Santuario from './pages/santuario';
import Suore from './pages/suore';
import Opere from './pages/opere';
import OrariMessePage from './pages/orari_messe';
import Scrivici from './pages/scrivici';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/santuario' element={<Santuario/>} />
          <Route path='/suore' element={<Suore/>} />
          <Route path='/opere' element={<Opere/>} />
          <Route path='/info' element={<Info/>} />
          <Route path='/foto_video' element={<FotoVideo/>} />
          <Route path='/orari_messe' element={<OrariMessePage/>} />
          <Route path='/scrivici' element={<Scrivici/>} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
