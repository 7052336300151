import React from "react";
import Container from '@mui/material/Container';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const gallery_root = "images/gallery/"
const filenames = ['image00.jpg', 'madre_flora.gif', 'volto-b_n.gif', 'madre_flora002.jpg', 'santuario.jpg', 'romania1.jpg', 'Immacolata.jpg', 'voltosanto emadre flora.jpg', 'image01.jpg', 'filippine2.jpg', 'filippine3.jpg', 'subheader.jpg', 'preghiera2jpg.jpg', 'preghiera1.jpg', '121227154_4738065672900276_6760056786475292086_o.jpg', 'IMG_20170121_103347.jpg', 'suormaria.gif', 'filippine1.gif', 'IMG_20180714_110301.jpg']


const openInNewTab = (url) => {
  window.open(url);
};

const FotoVideo = () => {

  const images = filenames.sort().reverse().map( filename => 
    <ImageListItem key={filename}>
      <img
        src={`${gallery_root + filename}?w=120&h=120&fit=crop&auto=format`}
        srcSet={`${gallery_root + filename}?w=120&h=120&fit=crop&auto=format&dpr=2 2x`}
        alt={filename}
        loading="lazy"
        onClick={() => openInNewTab(gallery_root + filename)}
      />
    </ImageListItem>  
  ) 

  return (
    <Container>
      <ImageList variant="woven" sx={{ width: "100%", height: "60%" }} cols={3} rowHeight={220}>
        {images}
      </ImageList>
    </Container>
  );
};
  
export default FotoVideo;