import React from "react";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Santuario = () => {
  return (
    <Container>
        <Typography variant="h2" component="div" gutterBottom>Nota Storica</Typography>
        <Typography variant="subtitle1" gutterBottom component="div">
        sull'origine del Santuario dedicato al Volto Santo
        </Typography>
        <br/>
        <br/>
        <a href="media/foto/santuario.jpg">
        <img src="media/foto/santuario.jpg" alt="Clicca per ingrandire la foto" width="270" height="212"/>
        </a>



        <p >La venerazione del Volto Santo di Gesù

        nel Santuario di Capodimonte - Napoli, si è sviluppata per volontà

        della Signora Flora Romano, nata a Napoli nell'anno 1899 e sposata con

        il signor Ernesto De Santis nell'anno 1917. I coniugi De Santis vissero

        in perfetta intesa soprattutto sul piano spirituale e religioso,

        desiderosi di vivere coerentemente la loro fede cristiana. <a href="?c=madre_flora">«Madre»

        Flora De Santis</a>, così sarà chiamata in seguito da tutti i devoti,

        fu donna tutta protesa verso il soprannaturale e il trascendente, dotata

        di tenerissimo amore per il Signore Gesù, di cui contemplava il Volto,

        «quel Volto luminoso che è stampato sul volto di tutti gli uomini».

        Ella pregava dinanzi ad un'immagine del Volto di Gesù, pervenutale

        attraverso "Crociata Missionaria", un periodico di

        "Propaganda Fide", al quale era molto interessata, ansiosa e

        tutta impegnata com'era della diffusione della buona stampa. Il

        frontespizio di "Crociata Missionaria" riproduceva la bella e

        dolce immagine del Volto di Cristo, delineato dalla pittrice Rina Maluta.

        Fu durante uno dei suoi momenti forti di preghiera che come si narra, il

        Volto di Gesù, da Madre Flora accuratamente sistemato in una cornice,

        si animò prodigiosamente trasfigurandosi, e nella sua bellezza radiante

        le rivolse la parola: "Flora, guarda questo Volto tanto offeso e

        ingiuriato. Amalo e fallo amare". Era il 10 febbraio 1932. Da quel

        momento la sua vita si trasformò totalmente, giacché l'unica

        preoccupazione fu quella di aderire alla voce del Signore che la

        chiamava a farsi privilegiato strumento di amore tra gli uomini. Forte

        nello spirito d ella missione affidatale nutriva totalmente la sua vita

        di valori soprannaturali, dedicandosi alla preghiera incessante, alla

        penitenza austera, e a un totale impegno di carità. Suo pensiero

        costante fu la diffusione nel mondo dell'immagine sacra del Volto di

        Cristo, il Volto Santo; la diffusione del suo Vangelo, del Suo divino

        messaggio: portare Cristo nel mondo significava incontrare i fratelli

        toccati da dolore, dalla sofferenza, dalla povertà e dalla sfiducia, e

        donare loro l'aiuto e il soccorso insieme alla consolazione e alla

        speranza. Nel periodo storico in cui incombeva nel mondo la guerra con

        le sue difficoltà e le sue durezze, e la città di Napoli ne fu

        brutalmente colpita, Madre Flora si sforzò più fortemente di

        dispensare la parola della solidarietà e della fiducia in Dio. Amò i

        poveri in maniera particolare e ad essi donò ciò che possedeva e

        quanto riceveva attraverso le vie della carità. L'intensa opera di

        diffusione del culto del Volto Santo trovò ampio riscontro nel cuore

        della gente di ogni ceto sociale. Da tutti i devoti del Volto Santo ella

        fu considerata la "Madre". In lei videro la donna ispirata, di

        straordinaria accoglienza, desiderosa di offrire sempre la giusta

        risposta di carità a quanti le si rivolgevano. Ogni risposta pensata,

        frutto del discernimento dello spirito, adeguata ai problemi di fede e

        di vita, era una risposta che assumeva il tono della profezia.

        Possedeva, infatti, il dono della profezia, che esplicò nella

        testimonianza profonda della fede e della carità. Madre Flora divenne

        così per tutti apostola instancabile e insonne del Volto Santo. Seppe

        aprire l'animo della gente al senso forte della preghiera, e si prodigò

        continuamente perché fosse compresa e conosciuta da quanti la

        frequentavano l'efficacia della grazia dei sacramenti, suscitando con

        la sua parola, nel cuore di tutti il desiderio vero della conversione.</p>

        <p>Padre Giacinto Ruggiero, francescano della Minoritica

        Provincia del Sacro Cuore di Gesù di Napoli, divenuto suo confessore le

        fu di valido aiuto nei momenti difficili e tormentati della sua vita,

        che non mancarono e ai quali ella reagì con la fermezza del suo

        temperamento e soprattutto con la fortezza della sua fede. P. Giacinto

        appoggiò e contribuì molto a dare giusta luce alla sua figura e agli

        avvenimenti presso le autorità ecclesiastiche.</p>

        <p >L'Em.mo Cardinale Alfonso Cataldo, nel

        1965, attraverso il suo Vicario, Mons. Erberto D'Agnese, con un decreto

        ufficiale, concesse, infatti, l'autorizzazione alla celebrazione della

        S. Messa quotidiana nella Cappellina della Casa del Volto Santo di

        Capodimonte," a beneficio spirituale dei devoti, degli orfanelli e

        degli assistenti degli orfanelli". Il 31 maggio 1969 la «Madre»

        concluse la sua giornata terrena: morì povera come visse,</p>

        <p >circondata da una schiera immensa di

        de voti, divenuti suoi figli spirituali. Il suo testamento a favore

        della Chiesa dì Napoli fu il segno della sua coerenza interiore e della

        integrità dei suoi sentimenti. In esso, in alternativa, ella indicò

        come suo erede l'Istituto delle Piccole Ancelle di Cristo Re. La Curia

        Arcivescovile, in considerazione del loro impegno spirituale, educativo

        e sociale, preferì che le Piccole Ancelle ne accettassero l'eredità,

        assumendone l'impegno testamentario della diffusione del culto e della

        edificazione del Santuario. S.Em.za il Card. Corrado Ursi, Arcivescovo

        di Napoli, nell'anno 1969, su istanza della Superiora Generale del

        tempo, Suor Franceschina Tuccillo, autorizzò, a norma dei sacri

        canoni,l'apertura della Casa Religiosa, con l'annesso oratorio pubblico.

        Mons.Vincenzo Frattini ne fu nominato Cappellano.</p>

        <p >Il terremoto del 1980 produsse grossi

        danni all'edificio e all'annessa cappellina della Casa del Volto Santo.

        Con la partecipazione fattiva, concreta, manuale di molti devoti, fu

        allestita, con le dovute autorizzazioni, in poco tempo, una

        Chiesa-prefabbricato, di dimensioni molto più ampie della primitiva

        Cappellina, che costituì il luogo sacro ove si svolgevano le

        celebrazioni liturgiche. L'immagine del Volto Santo vi dominava nel

        fondo, dietro l'altare. L'Istituto delle Piccole Ancelle, presente come

        comunità religiosa dal 1969 nella Casa del Volto Santo, si è impegnato

        a svolgere la sua missione sia a servizio del "Santuario", sia

        prodigandosi nella creazione delle opere annesse. Ampie strutture

        scolastiche modernamente attrezzate e altre iniziative sociali sono

        state messe a disposizione e organizzate per tutta la gente del luogo e

        delle zone circostanti, per fanciulli, giovani e adulti: ciascuno per le

        sue esigenze fruisce gratuitamente dei servizi disposti; tra l'altro, un

        moderno edificio a se stante ospita bambini e appartenenti a

        famiglie provate da difficoltà economiche, dove questi trovano i

        conforti necessari e godono di un'assistenza ed un'educazione complete.

        Altre opere, già in programma, sono state attuate, tra le quali è

        stata data la precedenza a un centro di spiritualità e a un centro di

        accoglienza dei pellegrini. Il numero dei devoti al Volto Santo di Gesù

        è cresciuto enormemente nel tempo. Oggi questi affluiscono come

        pellegrini da tanti luoghi o,pur risiedendo in paesi lontani, si nutrono

        della stessa linfa devozionale. Il sogno di Madre Flora di diffondere

        Cristo nel mondo attraverso un grande Santuario si è già avverato, e

        si avvera ogni giorno in proporzioni che meravigliano: la compostezza

        della preghiera è resa sempre più fervida, più intensa, più

        ecclesiale, attraverso l'impegno pastorale profuso da tutti i sacerdoti

        che sul luogo esercitano generosamente la loro missione

        evangelizzatrice, e per la dedizione diuturna delle Piccole Ancelle di

        Cristo Re, animatrici costanti della liturgia, che trovano nella

        celebrazione del Volto Santo una corrispondenza piena alla loro

        spiritualità cristocentrica e regale, mentre professano un apostolato

        attento e fervoroso.</p>

        <p >Una ampia "sala del

        pellegrino" è disponibile per quelli che provengono da luoghi

        lontani; possono usufruire della sala per consumare i loro pasti.</p>

        <p>Le Piccole Ancelle di Cristo Re sono grate al Signore

        di averle fatte oggetto di elezione e chiamate ad annunciarlo in questo

        luogo di fede. Sono grate a Madre Flora per averle scelte, amate e per

        aver posto nelle loro mani una immensa ricchezza spirituale da custodire

        e far lievitare. Credono nel dono dello Spirito che illumina e santifica

        e, rese forti del Suo aiuto, si propongono di diventare strumenti di

        salvezza per gli uomini: cercatrici attente e solerti del Volto di

        Cristo e testimoni nel mondo del Suo amore e della Sua misericordia.
        </p>
    </Container>
  );
};
  
export default Santuario;