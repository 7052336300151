import React from "react";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Opere = () => {
  return (
    <Container>
        <Typography variant="h2" component="div" gutterBottom>La missione nelle Filippine</Typography>
<div align="left">

        <p align="center">In questa pagina sono raccolte alcune testimonianze
        della missione che le suore &quot;Piccole ancelle di Cristo Re&quot;
        hanno svolto e continuano a svolgere nelle Filippine. Cliccate sulle
        foto per ingrandirle.</p>
        <p align="center">
        <img class="img-responsive" border="1" src="images/filippine1.gif" alt=""/></p>
        <p align="center">Bambini delle Filippine</p>
        <p align="center">
        <img class="img-responsive" border="1" src="images/filippine2.jpg" alt=""/></p>
        <p align="center">Incontro con i bambini</p>
        <p align="center">
        <img class="img-responsive" border="1" src="images/filippine3.jpg" alt=""/></p>
        <p align="center">Altro momento dei bambini delle filippine</p>
        <p align="center">
        <img class="img-responsive" border="1" src="images/romania1.jpg" alt=""/></p>
        <p align="center">Incontro con i giovani</p>
</div>
    </Container>
  );
};
  
export default Opere;