import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; // Grid version 1
import OrariMesse from '../components/OrariMesse';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import red from '@mui/material/colors/red';

import Typography from '@mui/material/Typography';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

const today = new Date();
let tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1)

const _programmiUrls = [
  "https://firebasestorage.googleapis.com/v0/b/casadelvoltosanto.appspot.com/o/public%2Favviso1.png?alt=media&token=8ba4bc20-aef9-4f98-bede-b1338875e894",
  "https://firebasestorage.googleapis.com/v0/b/casadelvoltosanto.appspot.com/o/public%2Fprogramma_del_mese.jpg?alt=media&token=f1572661-0047-400a-bede-91dd1d0a37d6",
  "https://firebasestorage.googleapis.com/v0/b/casadelvoltosanto.appspot.com/o/public%2Favviso2.jpg?alt=media&token=27cad756-f655-47c1-a4b3-f21dc24d08c0"];

function Home() {
  const [programmiUrls, setProgrammiUrls] = useState(_programmiUrls)


  const checkIfImageExists = (url, callback) => {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };
      
      img.onerror = () => {
        callback(false);
      };
    }
  }

  useEffect(() => {
    programmiUrls.forEach(url => {
      checkIfImageExists(url, (exists) => {
        if(!exists) {
          // remove url from programmiUrls
          console.log("removing url", url)
          const validUrls = programmiUrls.filter( u => u !== url)
          setProgrammiUrls(validUrls)
        }
      });
    });
  }, [programmiUrls]);


  const programmiImgs = programmiUrls.sort().reverse().map( url => <img key={url} src={url} width="100%" alt={url}></img> )
  return (
    <Container className="home-page-cnt" maxWidth={false}>
      <Grid container spacing={2}>
        <Grid item  xs={12} md={10}>
          <img src="/images/subheader.jpg" width="100%" alt="chiesa"></img>
        </Grid>
        <Grid item xs={12} md={2}>
          <OrariMesse date={today}></OrariMesse>
          <OrariMesse date={tomorrow}></OrariMesse>
          <Card>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                  <EventAvailableIcon/>
                </Avatar>
              }
              titleTypographyProps={{variant:'h6' }}
              title="Tutti i martedì del mese"
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">

                Dopo la messa delle 17,30 <b>adorazione eucaristica</b>
              </Typography>
            </CardContent>
          </Card>
          <br></br>
          <Card>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                  <EventAvailableIcon/>
                </Avatar>
              }
              titleTypographyProps={{variant:'h6' }}
              title="Ogni 2° e 4° mercoledì del mese"
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
              Ore 20,00 <b>adorazione eucaristica</b>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {programmiImgs}
    </Container>
  );
};
  
export default Home;